@import '../helpers/variables';

.user-details {
  background-color: var(--sonectBackgroundColor2);
  border-radius: 8px;
  box-shadow: var(--sonectWithdrawCard);
  padding: 16px;

  &__row {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }

    //onboarding-details.component input
    //input[type=date]::before {
    //  background: none;
    //  display: block;
    //  font-family: 'FontAwesome';
    //  content: '\f073';
    //  width: 15px;
    //  height: 20px;
    //  position: absolute;
    //  top: 16px;
    //  right: 2px;
    //  color: var(--sonectTitleColor);
    //}

    //input[type="date"]::-webkit-calendar-picker-indicator {
    //  opacity: 0;
    //}
    //
    //input[type="date"]::-webkit-date-and-time-value {
    //  text-align: left;
    //}

    .mat-icon {
      color: var(--sonectTitleColor);
      height: 15px;
      font-size: 15px;
      width: 15px;
      position: absolute;
      right: 2px;
      top: 18px;
    }
  }

  &__filed, &__value {
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  input.user-details__value {
    background-color: transparent;
    border: none;
    height: 18px;
    padding: 0;
  }

  .control-status {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    opacity: 0;
    transform: translateX(-100%);
    transition: all 0.2s linear;

    &--invalid {
      background-color: $sonect-red;
      opacity: 1;
      transform: translateX(0);
    }
  }

  &__field {
    color: var(--sonectMessage);
    font-family: $sonect-font;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 1px;
    margin-bottom: 4px;
  }

  &__value {
    color: var(--sonectTitleColor);
    font-size: 15px;
    line-height: 18px;
  }
}
