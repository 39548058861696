#loader {
  animation: loader 5s cubic-bezier(0.8, 0, 0.2, 1) infinite;
  height: 40px;
  width: 41px;
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
}

@keyframes loader {
  90% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(180deg);
  }
}

#loading-top {
  animation: loading-top 5s linear infinite;
  border-top: 20px solid #ff0069;
  border-right: 20px solid transparent;
  border-left: 20px solid transparent;
  height: 0;
  width: 1px;
  transform-origin: 50% 100%;
}

@keyframes loading-top {
  90% {
    transform: scale(0);
  }

  100% {
    transform: scale(0);
  }
}

#loading-bottom {
  animation: loading-bottom 5s linear infinite;
  border-right: 20px solid transparent;
  border-bottom: 20px solid #5ac4f1;
  border-left: 20px solid transparent;
  height: 0;
  width: 1px;
  transform: scale(0);
  transform-origin: 50% 100%;
}

@keyframes loading-bottom {
  10% {
    transform: scale(0);
  }

  90% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

#loading-line {
  animation: loading-line 5s linear infinite;
  border-left: 1px dotted #5ac4f1;
  height: 0;
  width: 0;
  position: absolute;
  top: 20px;
  left: 19px;
}

@keyframes loading-line {
  10% {
    height: 20px;
  }

  100% {
    height: 20px;
  }
}
