@import '../../../assets/css/helpers/variables';

.profile-layout {
  padding: $sonect-padding-top 16px 30px;
  justify-content: flex-start;
  position: relative;
  @media screen and (min-width: $sonect-breakpoint-small) {
    padding: $sonect-padding-top 20px 20px;
  }

  &__header {
    display: flex;
    align-items: center;

    h4 {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 0;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
