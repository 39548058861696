@import '../../../assets/css/helpers/variables';

.onboarding-page {
  padding: $sonect-padding-top 16px 20px;
  justify-content: flex-start;
  overflow-y: hidden;
  @media screen and (min-width: $sonect-breakpoint-small) {
    padding: $sonect-padding-top 20px 20px;
  }

  .page-header {
    background: var(--sonectBackgroundColor1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 25px;
    padding-bottom: 10px;
    z-index: 2;

    svg {
      cursor: pointer;

      &:first-child {
        path {
          stroke: var(--sonectTitleColor);
        }
      }
    }
  }

  h2 {
    font-size: 20px;
    font-weight: 600;
    margin: 20px 0 8px;
  }

  &__description {
    color: var(--sonectMessage);
  }

  .onboarding-form-row {
    margin-bottom: 10px;
    position: relative;

    input {
      background: var(--sonectBackgroundColor2);
      border: 1px solid transparent;
      border-radius: 8px;
      color: var(--sonectTitleColor);
      height: 44px;
      width: 100%;
      padding: 10px;
      overflow: hidden;
      text-overflow: ellipsis;

      ::placeholder {
        color: $sonect-grey;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
      }
    }

    input[type='date']::-webkit-calendar-picker-indicator {
      background: none;
      width: 24px;
      height: 24px;
    }
  }

  .onboarding-idenfy {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 224px;
    }
  }

  .onboarding-actions {
    margin: auto 0 0;

    app-main-button {
      margin-top: 40px;

      button.sonect-button {
        width: 100%;
      }
    }
  }
}
