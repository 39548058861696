@import '../../../assets/css/helpers/variables';

.login, .user {
  margin-top: 10%;
  margin-bottom: 20px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    .mat-mdc-icon-button {
      color: $sonect-black;
    }
  }
}

.login-form, .user-form {
  background-color: $sonect-white;
  border-radius: 3px;
  flex-basis: 480px;
  padding: 16px;
  box-shadow: 0 0 10px 0 rgb(255 255 255 / 75%);

  &__title {
    color: $sonect-black;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    margin-bottom: 0;

    i {
      color: $sonect-grey;
      font-size: 12px;
    }
  }

  .login-configuration {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-top: 8px;

    &__row {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    mat-radio-group {
      letter-spacing: normal;
      display: flex;
      margin-left: -10px;
    }

    &__title {
      color: $sonect-black;
      font-size: 14px;
      margin-bottom: 0;
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;

    button {
      margin-left: 16px;
    }
  }

  mat-form-field {
    width: 100%;

    input {
      color: $sonect-black;
      font-size: 12px !important;
      @media screen and (min-width: 520px) {
        font-size: 14px !important;
      }
    }
  }
}
