@import '../../../assets/css/helpers/variables';

.amount-slider, .nps .nps-form {
  .mdc-slider {
    margin: 0;
    width: 100%;

    .mdc-slider__thumb {
      box-shadow: none;
      border-radius: 50%;

      &-knob {
        background-color: var(--sonectPrimaryColor1);
        border-color: var(--sonectPrimaryColor1);
        background-image: url("/assets/img/illustrations/ruler.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 80%;
        box-shadow: none;
        height: 100%;
        width: 100%;
      }
    }

    .mdc-slider__value-indicator-container {
      bottom: 55px;
    }

    .mdc-slider__track {
      width: calc(100% + 48px);
      left: -24px;

      &--active {
        &_fill {
          border-color: var(--sonectPrimaryColor1);
        }
      }

      &--inactive {
        background-color: var(--sonectBackgroundColor2);
        height: 6px;
        opacity: 1;

        &:before {
          border: 1px solid var(--sonectBackgroundColor2);
        }
      }
    }
  }

  .mdc-slider.mdc-slider--disabled.not-verified, .mdc-slider.not-valid {
    opacity: 1;

    .mdc-slider__thumb {
      &-knob {
        background-color: var(--sonectPrimaryColor3);
        border-color: var(--sonectPrimaryColor3);
      }
    }

    .mdc-slider__track {
      &--active {
        &_fill {
          border-color: var(--sonectPrimaryColor3);
        }
      }

      &--inactive {
        background-color: var(--sonectBackgroundColor2);
        opacity: 1;

        &:before {
          border: 1px solid var(--sonectBackgroundColor2);
        }
      }
    }
  }
}

.nps .nps-form {
  .mdc-slider {
    .mdc-slider__track {
      &--inactive {
        background-color: var(--sonectGreyLight);
        height: 6px;
        opacity: 1;

        &:before {
          border: 1px solid var(--sonectGreyLight);
        }
      }
    }
  }
}
