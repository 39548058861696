/* GLOBAL STYLES */
@import '../../../assets/css/helpers/variables';

/* TODO: used in transaction-details.component.html, should be removed / improved */
.sonect-gray-color-100 {
  color: $sonect-grey !important;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-15 {
  font-size: calc(1rem + 1px);
  line-height: 18px;
}

/* TODO: used in transaction-details.component.html, should be removed / improved */

html {
  font-size: 14px;
  height: 100%;
}

body {
  background: var(--sonectBackgroundColor1) !important;
  color: var(--sonectTitleColor);
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
  margin: 0 auto;
}

/* DO NOT TOUCH - this to make sure the viewport is without the address bar and the lower tab bar of mobile browsers */
body {
  height: calc(var(--vh, 1vh) * 100);
  height: auto;
}

body {
  &.page {
    height: 100%;

    .page-content {
      align-items: center;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      min-height: 100%;
      max-width: none !important;
      padding: 5vh 8vw;
      opacity: 1;
      transition: opacity 0s ease-in-out;

      .page-title {
        font-size: 24px;
        font-weight: 600;
      }
    }

    .page-block {
      width: 100%;
      align-self: center;
      position: relative;
      z-index: 2;

      a {
        color: $sonect-pink;
        text-decoration: none;
        transition: all 0.05s ease-in-out;

        &:hover, &:focus {
          text-decoration: underline;
        }
      }
    }
  }
}

button {
  border: none;
  padding: 0;
}

.mat-mdc-form-field, .mat-mdc-floating-label, .mat-mdc-button, .mat-mdc-raised-button {
  letter-spacing: normal;
}

.mdc-button {
  font-family: $sonect-font;
  font-weight: 600;
}

.partner-setup-menu {
  .mat-mdc-menu-item {
    min-height: 32px;
    padding: 0 8px;
    input[type=file] {
      cursor: pointer;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      opacity: 0;
    }

    .mat-icon {
      color: $sonect-grey-3;
      margin-right: 10px;
    }

    .mdc-list-item__primary-text {
      color: $sonect-grey-3;
      font-size: 14px;
      font-weight: 500;
    }
  }
}

.page-config {
  max-width: 480px;
  min-width: 240px;
  margin: auto;
  display: flex;
  flex-direction: column;
  height: 100%;

  .profile-header {
    background: var(--sonectBackgroundColor1);
    display: flex;
    align-items: center;
    position: sticky;
    top: $sonect-padding-top;
    left: 16px;
    right: 16px;
    padding-bottom: 10px;
    z-index: 2;

    &::after {
      content: "";
      position: absolute;
      top: -$sonect-padding-top;
      width: 100%;
      height: $sonect-padding-top;
      background: var(--sonectBackgroundColor1);
      z-index: -1;
    }

    svg {
      &:first-child {
        path {
          stroke: var(--sonectTitleColor);
        }
      }
    }

    .logo {
      margin: 0 auto;
      transform: translateX(-9px);

      path {
        &:first-child {
          fill: var(--sonectTitleColor);
        }
      }
    }
  }

  .version {
    color: $sonect-grey;
    font-weight: 500;
    font-size: 16px;
    margin-top: 15px;
    text-align: center;
  }

  .illustration {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    overflow: hidden;
    transition: all 0.2s linear;
    text-align: center;

    img {
      max-width: 100%;
      max-height: 190px;
      transform: translateY(8px);
    }
  }
}


