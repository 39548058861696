@import '../../../assets/css/helpers/variables';

.confirm-dialog, .info-dialog-wrapper, .discover-shops-dialog, .emulate-partner-wrapper, .emulate-partner-wrapper-light {
  .mat-mdc-dialog-container {
    background-color: var(--sonectBackgroundColor1);
    display: block;
    border-radius: 4px;

    .mdc-dialog__surface {
      background-color: transparent;
      box-shadow: none;
      overflow-y: unset;
      padding: 24px;
    }
  }
}

.discover-shops-dialog {
  .mat-mdc-dialog-container {
    background: var(--sonectBackgroundColor2);
  }
}

.emulate-partner-wrapper-light {
  app-main-button {
    .sonect-button.secondary {
      border: 2px solid $sonect-black !important;
    }
  }
}

.cdk-overlay-dark-backdrop {
  background: rgba(70, 69, 69, 0.4);
}

