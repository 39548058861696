@import '../../../assets/css/helpers/variables';

.shop-status {
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  border: 3px solid $sonect-black-1;
  box-sizing: content-box;
  width: 10px;
  height: 10px;

  &.unavailable {
    background-color: $sonect-orange;
  }

  &.closed {
    background-color: $sonect-grey-4;
  }

  &.open {
    background-color: $sonect-green;
  }
}
